import { Component, ElementRef, Input, OnChanges, ViewChild,Output, EventEmitter } from '@angular/core';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { DatePipe } from '@angular/common';
import { CONSTANTS } from 'src/app/constants';
import { InitService } from 'src/app/service/init.service';
import { UserService } from 'src/app/service/user.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { DownloadDocumentService } from 'src/app/service/downloadDocument.service';
import { catchError, Observable, of, tap } from 'rxjs';
import { TitleService } from 'src/app/service/title.service';
@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss'],
  providers: [DatePipe]
})
export class PageBannerComponent implements OnChanges {
  @ViewChild('titlesection') titlesec: ElementRef<HTMLIFrameElement>;
  @Output() metaEvent = new EventEmitter<string>();
  @Input({ required: true }) docsTitle!: string;
  public title: string = '';
  public showMoreProductLines: string = '';
  public assetsPath = CONSTANTS.ASSETS_URL;
  public productLineTitle: string = '';
  public productLineMeta:any={};
  public productLine: string='';
  mailtoLink: string;
  public hrefLink: string;
  public isDocumentAPILoading:boolean=false;
  formattedTitle: string;
  // public downloadFailed: string[] = [];
  // public downloadFailedPopup: boolean = false;
  constructor(private eventBus: AmdocsEventBusService, private datePipe: DatePipe,private initService: InitService,private userService: UserService,private router:Router,private route:ActivatedRoute,private downloadService:DownloadDocumentService,private titleService:TitleService) {
    this.route.queryParams.subscribe(params => {
      this.productLine =params['productLine']? decodeURIComponent(params['productLine']):'';
      if(this.productLine){
        sessionStorage.setItem(CONSTANTS.SessionSTORAGE.productLine, this.productLine);
      }
    });

    this.eventBus.on(CONSTANTS.EVENTS.SCROLL_POSITION, (data:any) => {
      if (data>0) {
        this.titlesec.nativeElement.style.display='none';

      }else{
        this.titlesec.nativeElement.style.display='block';

      }
    }, true);
  }


  ngOnChanges() {

    if (this.docsTitle) {
      this.setTitle(this.docsTitle['KIN']);
      if(!sessionStorage.getItem(CONSTANTS.SessionSTORAGE.productLine)){
        sessionStorage.setItem(CONSTANTS.SessionSTORAGE.productLine,this.docsTitle?.['PL']?.[0]);
      }
      this.productLineTitle =sessionStorage.getItem(CONSTANTS.SessionSTORAGE.productLine);
      this.getProductLineMetaData(this.productLineTitle)// Use trim() to remove any leading/trailing whitespace
    }
    if (this.docsTitle && this.docsTitle['PL']) {
      this.showMoreProductLines = this.headingFormatter(
        this.docsTitle['APrdctTre'],
        '>',
        ' | ',
        'NA',
        '\n'
      );
      const heading = this.headingFormatter(
        [this.docsTitle['APrdctTre'][0]],
        '>',
        ' | ',
        'NA'
      );
      const lastUpdatedDate = this.docsTitle['LstUpdt'].length
        ?
          this.dateFormatter(this.docsTitle['LstUpdt'], 'dd MMM yyyy', true)  + ' | '


        : '';
      this.title = lastUpdatedDate + heading  ;
      if(this.title ){
        this.formattedTitle = this.title.replace(/\|/g, ' | ');
      }

    }
}

public getProductLineMetaData(productLine):void {
  this.initService
  .getProductLineMetaData({
    User_ID: this.userService.getEmail(),
    Emp_ID: this.userService.getEmployeeCode(),
    SO_Session_ID : "",
    IP_Details : "",
    DaC_Session_ID :environment.dacSessionId,
  })
  .subscribe((ProductLines) => {
    if(ProductLines){
      let filteredItems = ProductLines['getProductLineMetaData'];
      let matcheddata = this.getMatchProductLine(filteredItems);
      if(matcheddata){
        this.productLineMeta=matcheddata;
      }
    }

  });
}

navigateToProductLine(){
  this.eventBus.emit(CONSTANTS.EVENTS.PAGE_LOADER, true);
  this.router.navigate(['/productline'], {replaceUrl: true, state: { productLine: this.productLineMeta } });
}

navigatetoHome(){
  this.router.navigate(['/'], {replaceUrl: true});
}

private findValueInArray(values: any[]): any {
  for (const value of values) {
    if (value.name === this.productLineTitle) {
      return value;
    }
  }
  return undefined;
}

private findItemInCategory(category: any[]): any {
  for (const item of category) {
    if (item.values && Array.isArray(item.values)) {
      const foundValue = this.findValueInArray(item.values);
      if (foundValue) {
        return foundValue;
      }
    }
  }
  return undefined;
}

public getMatchProductLine(data: any): any {
  for (const category in data) {
    if (Array.isArray(data[category])) {
      const foundItem = this.findItemInCategory(data[category]);
      if (foundItem) {
        return foundItem;
      }
    }
  }
  return undefined; // Return undefined if no match is found
}

/**
  * @description This function is used to format the heading
  * @param sourceString
  * @param stringToBeReplace
  * @param replaceWith
  * @param eliminateText
  * @param stringAppendedAfterFormat
  * @returns Formatted String of heading
  */
headingFormatter(
  sourceString: [string],
  stringToBeReplace: string = '',
  replaceWith: string = '',
  eliminateText: string = '',
  stringAppendedAfterFormat: string = ''
): string {
  try {
    const formattedStrings = sourceString
      .map((productLine) =>
        productLine.split(stringToBeReplace).filter((str) => str !== eliminateText)
      )
      .map((arr) => arr.join(replaceWith));

    const result = formattedStrings.map((value, index) => {
      return index < (sourceString.length - 1) ? `${value},` : value;
    }).join(stringAppendedAfterFormat);

    return result;
  } catch {
    return '';
  }
}

download(): void {
  let item: any = this.docsTitle;
  this.isDocumentAPILoading = true;
  const documentId = item?.UnqI;
  const documentName = item?.KIN[0];

  this.downloadService.getDownload(documentId)
    .pipe(
      tap((response: any) => this.handleApiResponse(response, documentName)),
      catchError(error => this.handleApiError(error))
    )
    .subscribe();
}

handleApiResponse(response: any, documentName: string): void {
  if(response?.getDocumentContent?.url){
    const url = response?.getDocumentContent?.url;
    this.isDocumentAPILoading = false;
    const anchorUrl = document.createElement('a');
    anchorUrl.href = url;
    anchorUrl.download = ''; // Optional: specify a filename
    document.body.appendChild(anchorUrl);
    anchorUrl.click();
    document.body.removeChild(anchorUrl);
  }else{
    // this.showDownloadFailedPopup(documentName);
     this.isDocumentAPILoading = false;
    console.error('Error:', response);
  }
}


handleApiError(error: any): Observable<null> {
  this.isDocumentAPILoading = false;
  console.error('Error:', error);
  return of(null); // Return a fallback value or empty observable
}


setTitle(title): void {
  const staticTitle = 'Document Page'; // Set your static title here
  this.titleService.setTitle(title?title:staticTitle);
}

/**
  * @description This function is used to format the date
  * @param date
  * @param format
  * @param isInputMMDDYYYY
  * @returns Formatted Date
  */
dateFormatter(date: string, format :string='dd mm yyyy', isInputMMDDYYYY: boolean = true): string{
  if(isInputMMDDYYYY){
    const splitDate = date.split('/');
    date = `${splitDate[1]}\/${splitDate[0]}\/${splitDate[2]}`;
    const transformedDate = this.datePipe.transform(new Date(date), format);
    const formattedDate = transformedDate.replace(/(\b[a-z](?!\s))/g, x => x.toUpperCase());
    return formattedDate;
  }
  return this.datePipe.transform(new Date(date), format);
}
shareCourse() {
  this.hrefLink = window.location.origin;
  const docID = encodeURIComponent(this.docsTitle['UnqI']);  // encoding the data.UnqI value
  const subject = 'Document(s) from the Amdocs documentation portal'
  const body = encodeURIComponent(`Hi,

The following document(s) from the Amdocs documentation portal might interest you:

${this.docsTitle['KIN']}
${this.hrefLink}/document?DocUID=${docID}&SOSessionID=&StartPage=`);
    this.mailtoLink = `mailto:?subject=${subject}&body=${body}`;   
  
  }

    // closeDownloadError(){
    //   this.downloadFailed = [];
    //   this.downloadFailedPopup = false;
    // }
    // showDownloadFailedPopup(name:any){
    //   this.downloadFailed.push(name);
    //   this.downloadFailedPopup = true;
    // }
}

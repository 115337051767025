<div class="downloadFailed">


  <lib-amdocs-popup (closeCallback)="closepopUp()" [showCloseButton]="true" >
      <div class="popup-header">
        <span>Downloading failed</span>
      </div>
      <div class="popup-body">

        <p>
          The following documents could not be downloaded. Try again or contact
          <a href="mailto:care@amdocs.com">Amdocs product support</a>
        </p>
        <p class="persists">if the issue persists.</p>
        <ul class="document-list">
          <li *ngFor="let document of downloadFailed">
            <span class="documentName">{{ document }}</span>
            <span class="status">Download failed</span>
          </li>
        </ul>
      </div>

    </lib-amdocs-popup>

  </div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  transform(value: string, useAbbreviation: boolean = false): string {
    if (!value) return value;

    const [day, month, year] = value.split('/');
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const monthAbbreviations = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const monthName = useAbbreviation
      ? monthAbbreviations[parseInt(month, 10) - 1]
      : monthNames[parseInt(month, 10) - 1];
    return `${day} ${monthName} ${year}`;
  }
}
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AmdocsEventBusService } from 'amdocs-core-package';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private amdocsEventService: AmdocsEventBusService) { }  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const body = event.body;
          let data = body.data;
          const keyName = Object.keys(data)[0];
          const resultset= body.data[keyName];
          const status = resultset?.statusCode;
          const errorstatus= resultset?.['error']?.message;
          const errorDesc=resultset?.['params']?.ErrorDesc;
          const eligibility = resultset?.['params']?.hasOwnProperty('IsEligible')
          ? (resultset['params'].IsEligible ? 1 : 0)
          : -1;
          console.log(eligibility);
          if (data && (status!='200' && errorstatus!=null) || (errorDesc && (eligibility!=0))) {
            this.amdocsEventService.emit('isapiError',true);
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handle the error response
        console.error('HTTP Error:', error);
        return throwError(error);
      })
    );
  }
}
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-download-failed',
  templateUrl: './download-failed.component.html',
  styleUrls: ['./download-failed.component.scss']
})
export class DownloadFailedComponent {

  @Input() downloadFailed: any;
  @Output()  closeDownloadError: EventEmitter<any> = new EventEmitter();

  closepopUp(){
    this.closeDownloadError.emit();
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AmdocsAppInitService, AmdocsEventBusService } from 'amdocs-core-package';
import { SessionService } from '../service/session.service';
import { EligibilityService } from '../service/eligibility.service';
import { UserService } from '../service/user.service';
import { InitService } from '../service/init.service';
import { MarkUnMarkFavouriteProductLine } from '../service/markFavourite.service';

@Injectable({
  providedIn: 'root'
})
export class AppServices {
  constructor(
    public appInitService: AmdocsAppInitService,
    public router: Router,
    public eventBusService: AmdocsEventBusService,
    public sessionService: SessionService,
    public eligibilityService: EligibilityService,
    public userService: UserService,
    public initService: InitService,
    public markFavouriteProductList: MarkUnMarkFavouriteProductLine
  ) {}
}

<div class="DocAsCodeContainer" [ngClass]="{'productline':isProductLine}" [hidden]="!appLoaded || isDocument==''">
  <app-header [hidden]="pageNotfound"></app-header>
  <div *ngIf="showDocumentBanner && isValidDocument">
    <app-page-banner [hidden]="pageNotfound" [docsTitle]="defTitle"></app-page-banner>
  </div>
  <div *ngIf="(!showDocumentBanner && isUserEligible && withoutFilter)" style="display:inline">
    <app-banner></app-banner>
  </div>
    <div class="mainContainer">
      <router-outlet></router-outlet>
    </div>
    <div *ngIf="!showDocumentBanner" class="footerarea" [ngClass]="{'fixedFooter':isfixedFooter || noFooter || isWildcardRoute}">
      <app-footer [hidden]="pageNotfound || noFooter==true"></app-footer>
    </div>
</div>
<div class="loader" *ngIf="(!appLoaded)">
  <app-amdocs-loader></app-amdocs-loader>
</div>

<lib-amdocs-popup *ngIf="showTermsDialog">
  <app-terms-conditions></app-terms-conditions>
</lib-amdocs-popup>
<lib-amdocs-popup (closeCallback)="closeError()" [showCloseButton]="true" *ngIf="(apiError && apiError.errorType=='http') || (isapiError)">
  <div data-qa="error-modal" class="d-flex justify-content-center align-items-center error-notify">
  <div>
    <img src="/assets/icons/error.svg" alt="error">
    <h3>Error</h3>
    <span>An unexpected error occurred while retrieving your search results.</span>
    <span>Please try again later. If the issue persists, contact  <a href="mailto:care@amdocs.com">Amdocs product support</a>.</span>
  </div>
  </div>
</lib-amdocs-popup>

<app-amdocs-route-loader *ngIf="productLineLoaded"></app-amdocs-route-loader>

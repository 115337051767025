  import { Component } from '@angular/core';
  import { environment } from 'src/environments/environment';
  import { AmdocsEventBusService } from 'amdocs-core-package';
  import { CONSTANTS } from 'src/app/constants';
  @Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
  })
  export class FooterComponent {
    public currentYear:number = new Date().getFullYear();
    public copyRightsUrl=environment.footerConfig.copyrights;
    public amdocsUrl=environment.footerConfig.wwwAmdocs;
    constructor(private amdocsEventService:AmdocsEventBusService){

    }

    showTerms(){
      this.amdocsEventService.emit(CONSTANTS.EVENTS.SHOW_TERMS,true);
    }
    
  }

import { Component } from '@angular/core';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { CONSTANTS } from 'src/app/constants';
@Component({
  selector: 'app-quicklinks-navigate',
  template: '',
})
export class QuickLinksNavigateComponent {
  public assetsPath:string="/assets/icons/";
constructor(private eventService:AmdocsEventBusService){
  this.eventService.emit(CONSTANTS.EVENTS.APP_LOADED,false);
  this.eventService.emit('clearAllSelections', true);
  this.eventService.emit('clearInputSearch', true);
}
}
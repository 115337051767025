import { Injectable, NgZone } from '@angular/core';
import { Observable, BehaviorSubject, interval,Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { CONSTANTS } from '../constants';

// Declare a property for the subscription

@Injectable({
  providedIn: 'root',
})
export class NetworkService {

  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  private isOnlineSubject: BehaviorSubject<boolean>;
  public networkCheckSubscription: Subscription;
  

  constructor(private ngZone: NgZone,private eventBusService:AmdocsEventBusService) {
    this.isOnlineSubject = new BehaviorSubject<boolean>(navigator.onLine);
    this.eventBusService.on(CONSTANTS.EVENTS.ISEXTERNAL, (data: any) => {
      this.setupNetworkStatusCheck();
    }, true);

    this.eventBusService.on(CONSTANTS.EVENTS.IS_OFF_SUBSCRIPTION, (data: any) => {
      if (this.networkCheckSubscription) {
        this.networkCheckSubscription.unsubscribe();
      }
    }, true);
  }

  private setupNetworkStatusCheck(): void {
    // Emit initial status
    setTimeout(() => {
      // Periodically check network status
      this.ngZone.runOutsideAngular(() => {
        this.networkCheckSubscription =  interval(200).pipe(startWith(0)).subscribe(() => {
          this.ngZone.run(() => {
            this.checkNetworkStatus();
          });
        });
      });
    }, CONSTANTS.TIMEOUT.networkStatusDelay);
  }

  private checkNetworkStatus(): void {
    fetch('assets/translate/en.json?ts='+ (new Date().getTime()), { method: 'HEAD' }) // Replace 'favicon.ico' with a small local resource
      .then(() => {
        
        this.isOnlineSubject.next(true);
      })
      .catch(() => {
        this.isOnlineSubject.next(false);
      });
  }

  public get isOnline$(): Observable<boolean> {
    return this.isOnlineSubject.asObservable();
  }
}
<div class="page-not-found">
  <div class="error-content">
    <img src="/assets/icons/pageNotFound.svg" alt="pageNotFound" class="error-image" />
    <h1>Page not found</h1>

    <p class="page-fnt page-message">
      Sorry, this page is not available. The link you followed may be broken, or the page does not exist.
    </p>
    <div class="details">
    <p class="pagedetails">Here are some things you can do:</p>
    <ul class="page-fnt">
      <li>Go back to the<a (click)="openPreviousPage()" > previous page.</a></li>
      <li>Visit our<a (click)="openHomePage()" > homepage.</a></li>
      <li><a href="mailto:care@yourdomain.com"> Contact support.</a></li>
    </ul>
  </div>
  </div>
</div>


export const CONSTANTS = {
  SHARE_LIMIT:6,
  TOAST_TIME:10000,
  SHARE_LIMIT_MESSAGE:'You can select up to 6 documents at a time. You can download or share the selected documents or deselect some documents to proceed.',
    EVENTS: {
      OPEN_OPTION_MODAL:'open-option-modal',
      PAGINATOR:'paginator',
      CHECKBOX_SELECTED:'checkboxSelected',
      CHECKBOX_SELECTED_DOCUMENT:'checkboxSelectedDocument',
      DOCUMENT_LOADER:'document_loader',
      PAGE_LOADER:'pageloader',
      ACCESSDENIED:'accessdenied',
      IS_ELIGIBLE:'isEligible',
      PAGE_NOT_FOUND:'pageNotfound',
      APP_LOADED:'appLoaded',
      IS_FIXED_FOOTER:'isfixedFooter',
      IS_DOCUMENT:'isDocument',
      CLEAR_HOME_SELECTIONS:'clearHomeSelections',
      SCROLL_POSITION:'scrollPoS',
      SEARCH_INPUT:'searchInput',
      SEARCH_INPUT_CHANGE:'searchInputChange',
      FILTER_COUNT:'filterCount',
      DOCUMENT_URL_ERROR:'docsUrlError',
      PRODUCTLINE_LISTING:'productLineListing',
      SHOW_DOCUMENT:'showDocument',
      IS_OFF_SUBSCRIPTION:'IsOffSubscription',
      ISEXTERNAL:'IsExternal',
      SHOW_TERMS:'showterms',
      OPEN_FILTER:'openFilter',
      UPDATE_FILTER_ITEMS:'updateFilterItems',
      PRODUCTLINE:'productLine',
      FACET_DATA:'facetData',
      FAVOURITE:'favourite',
      WITHOUT_FILTER:'withoutFilter',
      FILTER_CLOSE_EVENT:'filterCloseEvent',
      FILTER_CLOSE_EVENT_DATA:'filterCloseEventData'
      
    },
    ASSETS_URL:"/assets/icons/",
    NoCategoryNaming:'Miscellaneous',
    CardTitleHeight:68,
    CardProductHeight:34,
    PageTitle:
      {
        HomePage:'Amdocs Product Documentation',
        ProductLine:'Amdocs Product Documentation : ',
        SearchResults:'Search Results',
        DocumentPage:'Document',
        AccessDeniedPage:'Access Denied',
        PageNotFound:'Page Not Found'

      },
    CHECK_ELIGIBILITY_TIMER:60,
    STORAGE:{
      userEligibility:'userEligibility',
      isInternal:'isInternal',
      isFirstLogin:'isFirstLogin',
      isProductLine:'isProductLine',
      eligibiltyDuration:'eligibiltyDuration',
      documentMessage:'documentMessage',
      isEligibleEvent:'isEligibleEvent',
      isdocumentEligible:'isdocumentEligible',
    },
    SessionSTORAGE:{
     startPage:'StartPage',
     shareId:'shareId',
     productLine:'productLine',
     isdocumentCallback:'isdocumentCallback',
     isLoaded:'isloaded',
     SOSessionID:'SOSessionID',
     documentId:'DocUID',
     documentExpiration:'documentExpiration',
     documentUrl:'documentUrl',
    },
    TIMEOUT:{
      documentError:1000,
      eligibilityError:1000,
      pageLoader:1000,
      documentMessage:4000,
      filterLoadDelay:100,
      filterScrollDelay:10,
      networkStatusDelay:5000,
    }

}


import { Injectable } from '@angular/core';
import { AmdocsAppsyncClientService } from 'amdocs-core-package';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class DownloadDocumentService {
  constructor(private appSyncClient: AmdocsAppsyncClientService) {}

  getDownload(DocNum: string): Observable<any> {
    const downloadDocumentQuery = `query GetDocumentContent($documentId: String!) {
      getDocumentContent(documentId: $documentId) {
        fileType
        message
        url
      }
    }`;

    const paramsvalues = {
      documentId: DocNum
    };

    return this.appSyncClient.call(downloadDocumentQuery, 'GetDocumentContent', paramsvalues).pipe(
      map((res: any) => res),
      catchError(err => {
        console.log(err);
        return of({ error: err });
      })
    );
  }
}
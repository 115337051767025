import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AmdocsEventBusService } from 'amdocs-core-package';
import { CONSTANTS } from 'src/app/constants';
import { EligibilityService } from 'src/app/service/eligibility.service';
import { TitleService } from 'src/app/service/title.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-accessDeenied',
  templateUrl: './accessDenied.component.html'
  

})
export class AccessDeniedComponent implements OnDestroy, OnInit{

  constructor(private eventBus: AmdocsEventBusService,private router: Router,private userService:UserService,private eligibilityService:EligibilityService,private titleService:TitleService) {
     this.eventBus.emit(CONSTANTS.EVENTS.APP_LOADED,true);
     this.eventBus.emit(CONSTANTS.EVENTS.IS_FIXED_FOOTER,true);
     this.checkisEligible();
  }

  ngOnInit() : void{
    this.setTitle();
  }

  checkisEligible() : void {
    const navigation = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
        if (navigation.type == 'reload') {
          let userData= {
            "SO_Session_ID": sessionStorage.getItem(CONSTANTS.SessionSTORAGE.SOSessionID)?sessionStorage.getItem(CONSTANTS.SessionSTORAGE.SOSessionID):'',
            "User_ID": this.userService.getEmail(),
            "IP_Details": "",
            "Emp_ID": this.userService.getEmployeeCode(),
            "DaC_Session_ID": ""
          }
      this.eligibilityService
      .getEligibility(userData)
      .subscribe((eligible) => {
        if(eligible?.getEligibilityRuleFromAPS?.params['IsEligible']){
          localStorage.setItem(CONSTANTS.STORAGE.isProductLine,'1');
          localStorage.setItem(CONSTANTS.STORAGE.userEligibility,'true');
          this.router.navigate(['/']);
          }
      }); 
      
    }
  }

  setTitle(): void {
    const staticTitle = CONSTANTS.PageTitle['AccessDeniedPage']; // Set your static title here
    this.titleService.setTitle(staticTitle);
  }

  ngOnDestroy(): void {
    this.eventBus.emit(CONSTANTS.EVENTS.IS_FIXED_FOOTER,false);
  }
  
}
